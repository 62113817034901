<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Promo List
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<div role="group" class="form-group">
									<label for="product" class=""> Product </label>
									<select
										v-model="moreParams.product"
										id="product"
										class="form-control"
									>
										<option :value="null">ALL</option>
										<option
											v-for="prod in products"
											:key="prod.id"
											:data-key="prod.id"
											:value="prod.id"
										>
											{{ prod.name }}
										</option>
									</select>
								</div>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top:27px;"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add()"
										id="btn"
										color="success"
										style="margin-top:27px; margin-left:5px;"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height:900px;">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								:detail-row-component="detailRow"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-type" slot-scope="props">
									<CBadge
										:color="props.rowData.type == 'in' ? 'success' : 'danger'"
									>
										{{ props.rowData.type }}
									</CBadge>
								</template>
								<template slot="slot-date" slot-scope="props">
									{{
										props.rowData.createdAt
											.replace("T", " ")
											.replace(".000Z", "")
									}}
								</template>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										style="margin-left:3px;"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<div role="group" class="form-group">
						<label for="product" class=""> Product </label>
						<select v-model="frm.productId" id="product" class="form-control">
							<option
								v-for="prod in products"
								:key="prod.id"
								:data-key="prod.id"
								:value="prod.id"
							>
								{{ prod.name }}
							</option>
						</select>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="6">
					<div role="group" class="form-group">
						<label for="type" class=""> Type </label>
						<select v-model.number="frm.type" id="type" class="form-control">
							<option :data-key="'in'" :value="'in'">
								IN
							</option>
							<option :data-key="'out'" :value="'out'">
								OUT
							</option>
						</select>
					</div>
				</CCol>
				<CCol md="6">
					<CInput
						label="Amount"
						type="number"
						v-model.number="frm.amount"
						placeholder="Amount"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						label="Batch number"
						v-model="frm.batchNumber"
						placeholder="batch number"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						label="Recipient"
						v-model="frm.recipient"
						placeholder="recipient"
					/>
				</CCol>
				<CCol md="12">
					<CTextarea
						label="Note"
						v-model="frm.note"
						placeholder="Note..."
						rows="4"
					/>
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.name : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
import TransactionProductDetail from "./TransactionProductDetail";

export default {
	data() {
		return {
			uri: basuri + "transaction/product",
			moreParams: { filter: null, product: null },
			selectedId: null,
			loading: false,
			modal: false,
			detailRow: TransactionProductDetail,
			products: [],
			frm: {
				productId: null,
				type: "in",
				amount: 1,
				batchNumber: "",
				note: "",
				orderId: 0,
				recipient: "",
			},
			fields: [
				{
					title: "Date",
					name: "slot-date",
				},
				{
					name: "product.name",
					title: "Product",
				},
				{
					name: "slot-type",
					title: "Type",
					dataClass: "text-center",
				},
				{
					name: "amount",
					title: "Amount",
				},
				{
					name: "stock",
					title: "Stock",
				},
				{
					name: "recipient",
					title: "Recipient",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	mounted() {
		this.getProducts();
	},
	watch: {
		"moreParams.product": debounce(function(val) {
			this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, product: null };
			this.$refs.vuetable.refresh();
		},
		add(data = null) {
			if (data) {
				this.frm = JSON.parse(JSON.stringify(data));
				this.selectedId = data.id;
			} else {
				this.frm = {
					productId: null,
					type: "in",
					amount: 1,
					batchNumber: "",
					note: "",
					orderId: "",
					recipient: "",
				};
				this.selectedId = null;
			}
			this.modal = true;
		},
		getProducts: debounce(async function() {
			const { data } = await this.$http.get("product", {
				params: { page: 1, per_page: 3000 },
			});
			this.products = data.data.data;
		}, 1000),
		save: debounce(async function() {
			this.loading = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"transaction/product/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post(
						"transaction/product",
						this.frm
					);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
